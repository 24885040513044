export const SET_PLAYING = 'SET_PLAYING'
export const SET_AUTO_PLAYED = 'SET_AUTO_PLAYED'
export const SET_CURRENT_TRACK_ID = 'SET_CURRENT_TRACK_ID '
export const SET_PLAYLIST = 'SET_PLAYLIST'
export const SET_SHOW_PLAYER = 'SET_SHOW_PLAYER'
export const SET_SHOW_DOWNLOAD_PROMPT = 'SET_SHOW_DOWNLOAD_PROMPT'
export const SET_SHOW_SUBSCRIPTION_PROMPT = 'SET_SHOW_SUBSCRIPTION_PROMPT'
export const SET_TOGGLE_SCREEN = 'SET_TOGGLE_SCREEN'
export const SET_LISTENER_COUNT = 'SET_LISTENER_COUNT'
export const SET_TRACK_COUNT = 'SET_TRACK_COUNT'
export const SET_PLAYLIST_DETAILS = 'SET_PLAYLIST_DETAILS'
export const RESET_PLAYLIST_DETAILS = 'RESET_PLAYLIST_DETAILS'
export const SET_CURRENT_PLAYING_CATALOG = 'SET_CURRENT_PLAYING_CATALOG'
export const SET_JOINED_LIVEROOM = 'SET_JOINED_LIVEROOM'
export const SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT =
    'SET_CURRENT_LIVEROOM_PARTICIPANT_COUNT'
export const SET_CURRENT_LIVE_SPEAKERS = 'SET_CURRENT_LIVE_SPEAKERS'
export const SET_CURRENT_LIVEROOM = 'SET_CURRENT_LIVEROOM'
export const SET_PLAYING_RADIO_LISTENER_COUNT =
    'SET_PLAYING_RADIO_LISTENER_COUNT'
export const SET_SHOW_LOGIN_PROMPT = 'SET_SHOW_LOGIN_PROMPT'
export const SET_CURRENT_CONTENT_ID = 'SET_CURRENT_CONTENT_ID'
export const SET_ELAPSED_TIME = 'SET_ELAPSED_TIME'
